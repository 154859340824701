.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.video-responsive {
  overflow: hidden;
  position: relative;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  width: 100%;
  position: absolute;
}

.column {
  float: left;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

::-webkit-scrollbar
{
  width: 12px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  background-color: #D62929;
  background-image: -webkit-linear-gradient(90deg,
                        transparent,
                        rgba(0, 0, 0, 0.4) 50%,
                        transparent,
                        transparent)
}

body::-webkit-scrollbar-track {
    background-color: black;
}

strong {
  color: red;
}

a:link {
  color: #DD0000;
}

a:visited {
  color: #CC0000;
}

a:hover {
  color: #FF0000;
}

a {
  text-decoration: none;
  font-weight: bold;
}

body {
    overflow: overlay;
    background-color: #330000;
}