:root {
  --fadeDur: 9s;
  --hueDur: 20s;
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0.2; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.2; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.2; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.2; }
  50%  { opacity:0.2; }
  100% { opacity:1; }
}
@keyframes hueAnimation {
  0%   { filter: hue-rotate(0deg) invert(0%); }
  17%   { filter: hue-rotate(60deg) invert(100%); }
  33%   { filter: hue-rotate(120deg) invert(0%); }
  50%   { filter: hue-rotate(180deg) invert(100%); }
  67%   { filter: hue-rotate(240deg) invert(0%); }
  83%   { filter: hue-rotate(300deg) invert(100%); }
  100% { filter: hue-rotate(360deg) invert(0%); }
}
@-o-keyframes hueAnimation{
  0%   { filter: hue-rotate(0deg) invert(0%); }
  17%   { filter: hue-rotate(60deg) invert(100%); }
  33%   { filter: hue-rotate(120deg) invert(0%); }
  50%   { filter: hue-rotate(180deg) invert(100%); }
  67%   { filter: hue-rotate(240deg) invert(0%); }
  83%   { filter: hue-rotate(300deg) invert(100%); }
  100% { filter: hue-rotate(360deg) invert(0%); }
}
@-moz-keyframes hueAnimation{
  0%   { filter: hue-rotate(0deg) invert(0%); }
  17%   { filter: hue-rotate(60deg) invert(100%); }
  33%   { filter: hue-rotate(120deg) invert(0%); }
  50%   { filter: hue-rotate(180deg) invert(100%); }
  67%   { filter: hue-rotate(240deg) invert(0%); }
  83%   { filter: hue-rotate(300deg) invert(100%); }
  100% { filter: hue-rotate(360deg) invert(0%); }
}
@-webkit-keyframes hueAnimation{
  0%   { filter: hue-rotate(0deg) invert(0%); }
  17%   { filter: hue-rotate(60deg) invert(100%); }
  33%   { filter: hue-rotate(120deg) invert(0%); }
  50%   { filter: hue-rotate(180deg) invert(100%); }
  67%   { filter: hue-rotate(240deg) invert(0%); }
  83%   { filter: hue-rotate(300deg) invert(100%); }
  100% { filter: hue-rotate(360deg) invert(0%); }
}
.animate-flicker {
   -webkit-animation: flickerAnimation var(--fadeDur) ease-in-out infinite, hueAnimation var(--hueDur) ease-in-out infinite;
   -moz-animation: flickerAnimation var(--fadeDur) ease-in-out infinite, hueAnimation var(--hueDur) ease-in-out infinite;
   -o-animation: flickerAnimation var(--fadeDur) ease-in-out infinite, hueAnimation var(--hueDur) ease-in-out infinite;
    animation: flickerAnimation var(--fadeDur) ease-in-out infinite, hueAnimation var(--hueDur) ease-in-out infinite;
}

.strokeme {
  text-shadow: -1px -1px 0 #200, 1px -1px 0 #200, -1px 1px 0 #200, 1px 1px 0 #200;
}