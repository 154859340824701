:root {
  --fadeMax: 0.8;
  --fadeMin: 0.2;
  --fadeDur: 7.2s;
}


@keyframes flickerAnimation {
  0%   { opacity:var(--fadeMax); }
  50%  { opacity:var(--fadeMin); }
  100% { opacity:var(--fadeMax); }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:var(--fadeMax); }
  50%  { opacity:var(--fadeMin); }
  100% { opacity:var(--fadeMax); }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:var(--fadeMax); }
  50%  { opacity:var(--fadeMin); }
  100% { opacity:var(--fadeMax); }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:var(--fadeMax); }
  50%  { opacity:var(--fadeMin); }
  50%  { opacity:var(--fadeMin); }
  100% { opacity:var(--fadeMax); }
}
.static {
   -webkit-animation: flickerAnimation var(--fadeDur) ease-in-out infinite;
   -moz-animation: flickerAnimation var(--fadeDur) ease-in-out infinite;
   -o-animation: flickerAnimation var(--fadeDur) ease-in-out infinite;
    animation: flickerAnimation var(--fadeDur) ease-in-out infinite;
}

.bg {
    background-image: linear-gradient(to bottom right, red, black);
}